import ReactLoading from "react-loading";
import {
  Avatar,
  Badge,
  Box,
  IconButton,
  Container,
  Grid,
  InputBase,
  Typography,
} from "@material-ui/core";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import CallIcon from "@material-ui/icons/Call";
import ChatIcon from "@material-ui/icons/Chat";
import React, { useContext, useEffect, useMemo, useState } from "react";
import useStyles from "./useStyles";
import SearchIcon from "@material-ui/icons/Search";
import { useSelector } from "react-redux";
import Conversation from "./Conversation";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { api, getProfile } from "../utils/userApi";
import { CallContext } from "./CallContext";

const ChatPage = ({ socket, user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [query, setQuery] = useState(null);
  const userId = user._id;
  const { makeCall } = useContext(CallContext);
  const [friendToChat, setFriendToChat] = useState(null);
  const [newFriendList, setNewFriendList] = useState(null);
  const [unseenConversations, setUnseenConversations] = useState(null);
  const myConversations = useSelector((state) => state.conversations);
  const userProfile = useSelector((state) => state.profile);
  const { friendList } = userProfile;
  const conversations = useMemo(() => {
    const prevConversations = myConversations
      ?.filter((conversation) => conversation.messages?.length > 0)
      ?.sort(
        (a, b) =>
          new Date(b.messages[b.messages.length - 1].date) -
          new Date(a.messages[a.messages.length - 1].date)
      );
    const emptyConversations = myConversations?.filter(
      (conversation) => conversation.messages?.length === 0
    );
    return [...prevConversations, ...emptyConversations];
  }, [myConversations]);

  useEffect(() => {
    setUnseenConversations(
      conversations?.map((conversation) => {
        const filteredMessages = conversation.messages?.filter(
          (message) => message.seen === false && message.senderId !== userId
        );
        return { conversationId: conversation._id, unseen: filteredMessages };
      })
    );
    return () => setUnseenConversations(null);
  }, [conversations, userId]);
  useEffect(() => {
    const friends =
      friendList &&
      conversations.length > 0 ?
      conversations?.map((conversation) => {
        return friendList?.find(
          (friend) => friend.conversationId === conversation._id
        );
      }):null;
    setNewFriendList(
      query
        ? friends.filter(
            (friend) =>
              friend.firstName.toLowerCase().startsWith(query.toLowerCase()) ||
              friend.lastName.toLowerCase().startsWith(query.toLowerCase())
          )
        : friends
    );
    return () => setNewFriendList(null);
  }, [conversations, friendList, query]);

  useEffect(() => {
    if (location?.state) {
      setFriendToChat(
        friendList?.find((friend) => friend.conversationId === location?.state)
      );
      navigate("/");
    }
    return () => setFriendToChat(null);
  }, [navigate, friendList, location]);
  const handleCall = async (e, id, friendName, friendImage, isVideoCall) => {
    e.preventDefault();
    makeCall(id, friendName, friendImage, isVideoCall);
    isVideoCall ? navigate("/videoCall") : navigate("/voiceCall");
  };
  return (
    <Container className={classes.chatPageContainer}>
      {loading ? (
        <div className={classes.loadingChatContainer}>
          <div>
            <ReactLoading
              type="spokes"
              color="#191919"
              height={50}
              width={50}
            />
          </div>
        </div>
      ) : friendList?.length > 0 ? (
        <Grid
          container
          spacing={1}
          justifyContent="center"
          className={classes.chatContainer}
        >
          <Grid item md={friendToChat ? 4 : 8}>
            <Box className={classes.sideList} boxShadow={3}>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                  placeholder="Filter…"
                  classes={{
                    root: classes.inputRoot,
                    input: classes.inputInputChat,
                  }}
                  inputProps={{ "aria-label": "search" }}
                  onChange={(e) => setQuery(e.target.value)}
                />
              </div>
              <div className={classes.friendList}>
                {newFriendList ? (
                  newFriendList?.map((friend) => (
                    <Grid
                      container
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      wrap="nowrap"
                      spacing={1}
                      key={friend.conversationId}
                      style={
                        friend?.status !== "friend"
                          ? {
                              filter: "brightness(0.5)",
                            }
                          : { filter: "none" }
                      }
                    >
                      <Grid
                        item
                        container
                        justifyContent="flex-start"
                        wrap="nowrap"
                        spacing={1}
                        direction="row"
                        alignItems="center"
                      >
                        <Grid
                          item
                          onClick={() => navigate(`/profile/${friend._id}`)}
                        >
                          <Avatar
                            style={
                              friend?.isOnline
                                ? { border: "3px solid #adff2f" }
                                : { border: "3px solid #808080" }
                            }
                            alt={friend.firstName}
                            src={`${api}/${friend.image}`}
                          />
                        </Grid>
                        <Grid item>
                          {`${friend.firstName} ${friend.lastName}`}
                        </Grid>
                      </Grid>
                      <Grid item> </Grid>
                      <Grid
                        item
                        container
                        justifyContent="flex-end"
                        wrap="nowrap"
                        spacing={1}
                        style={{ width: "fit-content" }}
                      >
                        <Grid item className={classes.relative}>
                          <IconButton
                            className={classes.iconBtn}
                            onClick={() => setFriendToChat(friend)}
                          >
                            <ChatIcon />
                          </IconButton>
                          <Badge
                            overlap="rectangular"
                            className={classes.absoluteBadge}
                            badgeContent={
                              unseenConversations &&
                              unseenConversations[
                                unseenConversations.findIndex(
                                  (conversation) =>
                                    conversation.conversationId ===
                                    friend.conversationId
                                )
                              ]?.unseen?.length
                            }
                            color="secondary"
                          >
                            {" "}
                          </Badge>
                        </Grid>
                        {friend.status === "friend" && (
                          <>
                            <Grid item>
                              <IconButton
                                disabled={!friend.isOnline}
                                className={classes.iconBtn}
                                onClick={(e) => {
                                  handleCall(
                                    e,
                                    friend._id,
                                    `${friend.firstName} ${friend.lastName}`,
                                    friend.image,
                                    false
                                  );
                                }}
                              >
                                <CallIcon />
                              </IconButton>
                            </Grid>
                            <Grid item>
                              <IconButton
                                disabled={!friend.isOnline}
                                className={classes.iconBtn}
                                onClick={(e) => {
                                  handleCall(
                                    e,
                                    friend._id,
                                    `${friend.firstName} ${friend.lastName}`,
                                    friend.image,
                                    true
                                  );
                                }}
                              >
                                <VideoCallIcon />
                              </IconButton>
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="subtitle1" color="textSecondary">
                    Sorry, there is no friends to display{" "}
                  </Typography>
                )}
              </div>
            </Box>
          </Grid>
          {friendToChat && (
            <Grid item md={8}>
              <Box
                className={classes.conversationBox}
                boxShadow={3}
                style={{ height: "100%" }}
              >
                <Conversation
                  user={user}
                  friendToChat={friendToChat}
                  setFriendToChat={setFriendToChat}
                  socket={socket}
                />
              </Box>
            </Grid>
          )}
        </Grid>
      ) : (
        <Navigate to={`/friends`} />
      )}
    </Container>
  );
};

export default ChatPage;
