import React, { useContext, useEffect } from "react";
import { Button, Container, Grid, Typography } from "@material-ui/core";
import useStyles from "./useStyles";
import { CallContext } from "./CallContext";
import { useNavigate } from "react-router-dom";
import busy from "../utils/ringtones/busy-signal-sound.mp3";
import disconnected from "../utils/ringtones/phone-disconnected.mp3";
import ringing from "../utils/ringtones/ringing.mp3";

const VideoCall = ({ socket }) => {
  const navigate = useNavigate();
  const {
    stream,
    callAccepted,
    callEnded,
    myMedia,
    userMedia,
    endCall,
    callStatus,
    handleCallStatus,
  } = useContext(CallContext);
  useEffect(() => {
    if (stream) {
      myMedia.current.srcObject = stream;
    }
  }, [myMedia, stream]);
  useEffect(() => {
    const handleCallEnded = () => {
      navigate("/");
      window.location.reload();
    };
    callEnded && handleCallEnded();
  }, [callEnded, navigate]);
  const classes = useStyles();

  return (
    <Container>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        spacing={2}
        className={classes.videoCallGrid}
      >
        {!callAccepted && (
          <>
            {" "}
            {callStatus === "ringing" && (
              <audio playsInline src={ringing} loop autoPlay />
            )}
            {callStatus === "busy" && (
              <audio playsInline loop src={busy} autoPlay />
            )}
            {(callStatus === "rejected" || callStatus === "noResponse") && (
              <audio playsInline src={disconnected} loop autoPlay />
            )}
          </>
        )}
        <Grid item className={classes.videoContainer}>
          {stream && (
            <video
              playsInline
              muted
              ref={myMedia}
              autoPlay
              className="myVideo"
            />
          )}
        </Grid>
        <Grid item className={classes.videoContainer}>
          {callAccepted && !callEnded && (
            <video playsInline ref={userMedia} autoPlay className="userVideo" />
          )}
        </Grid>{" "}
      </Grid>
      {!callAccepted && (
        <Typography variant="h5" className={classes.videoCallStatus}>
          {" "}
          {handleCallStatus()}
        </Typography>
      )}

      <Button
        fullWidth
        variant="contained"
        className={classes.endVideoCallBtn}
        onClick={() => {
          navigate("/");
          endCall();
        }}
      >
        End Call
      </Button>
    </Container>
  );
};

export default VideoCall;
