import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ReactLoading from "react-loading";
import Nav from "./components/Nav";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Login from "./components/Login";
import Signup from "./components/Signup";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import NotFound from "./components/NotFound";
import { CssBaseline, Grid, Typography } from "@material-ui/core";
import useStyles from "./components/useStyles";
import { api, authorize, getProfile } from "./utils/userApi";
import Profile from "./components/Profile";
import {
  acceptFriendRequest,
  addProfileData,
  changeOnlineStatus,
  receiveFriendRequest,
  removeFriendRequest,
} from "./store/actions/userActions";
import { io } from "socket.io-client";
import {
  addNotification,
  getNotifications,
} from "./store/actions/notificationAction";
import ChatPage from "./components/ChatPage";
import {
  addNewMessage,
  getConversations,
} from "./store/actions/conversationAction";
import CompleteInfo from "./components/CompleteInfo";
import VideoCall from "./components/VideoCall";
import { ContextProvider } from "./components/CallContext";
import ResponseCall from "./components/ResponseCall";
import VoiceCall from "./components/VoiceCall";
import FriendList from "./components/FriendList";

const App = () => {
  const [isUser, setIsUser] = useState(false);
  const dispatch = useDispatch();
  const classes = useStyles();
  const [socket, setSocket] = useState(null);
  const [onlineStatus, setOnlineStatus] = useState(true);
  const [loading, setLoading] = useState(true);

  const userProfile = useSelector((state) => state.profile);
  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });
    window.addEventListener("online", () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });
      window.removeEventListener("online", () => {
        setOnlineStatus(true);
      });
    };
  }, []);
  useEffect(() => {
    if (onlineStatus) {
      setLoading(true);
      authorize()
        .then(async (res) => {
          if (res.message === "ok") {
            const { notifications, ...user } = res.user;
            user?.friendList?.length > 0
              ? await Promise.all(
                  user.friendList.map(async (friend) => {
                    const res = await getProfile(friend._id)
                      .then((res) => {
                        return {
                          ...res.user,
                          conversationId: friend.conversationId,
                          status: friend.status,
                        };
                      })
                      .catch((err) => console.log(err.message));
                    return res;
                  })
                ).then((res) =>
                  dispatch(addProfileData({ ...user, friendList: res }))
                )
              : dispatch(addProfileData(user));
            dispatch(getNotifications(notifications));
            dispatch(getConversations(res.conversations));
            setIsUser(true);
          } else {
            setIsUser(false);
          }
        })
        .then(() => setTimeout(() => setLoading(false), 1000));
    }
  }, [dispatch, onlineStatus]);

  useEffect(() => {
    isUser &&
      setSocket(
        io(api, {
          transports: ["websocket"],
          // query: { userId: userProfile._id },
          // withCredentials: true,
          // extraHeaders: {
          // },
        })
      );
  }, [isUser, userProfile._id]);
  useEffect(() => {
    socket?.on("connect", () => {
      socket?.emit("online", userProfile._id);
    });
  }, [socket, userProfile._id]);

  useEffect(() => {
    socket?.on("getNotification", ({ notification }) => {
      const newNotification = { ...notification, isRead: false, new: true };
      dispatch(addNotification(newNotification));
      switch (newNotification.status) {
        case "receivedRequest":
          return dispatch(receiveFriendRequest(newNotification));
        case "acceptedRequest":
          return dispatch(
            acceptFriendRequest(
              newNotification._id,
              newNotification.conversationId
            )
          );
        case "deletedFriend":
          return dispatch(removeFriendRequest(newNotification._id));
        default:
          return;
      }
    });
    socket?.on("receiveMessage", ({ conversationId, message }) => {
      dispatch(addNewMessage(conversationId, message));
    });

    socket?.on("friendOnlineStatus", ({ id, isOnline }) => {
      dispatch(changeOnlineStatus(id, isOnline));
    });
    return () => {
      socket?.off("getNotification");
      socket?.off("receiveMessage");
      socket?.off("friendOnlineStatus");
    };
  }, [dispatch, socket]);
  return (
    <div className={`App ${classes.root}`}>
      <CssBaseline />
      <ContextProvider
        socket={socket}
        myId={userProfile._id}
        myName={`${userProfile.firstName} ${userProfile.lastName}`}
        myImage={userProfile.image}
      >
        <Router>
          <Grid item>
            <Nav isUser={isUser} setIsUser={setIsUser} />
          </Grid>
          <main className={classes.main + " " + classes.grow}>
            {!onlineStatus && (
              <Typography
                variant="body1"
                style={{
                  backgroundColor: "#9b9b9b",
                  textAlign: "center",
                  padding: "2px",
                }}
              >
                It seems you are offline.
              </Typography>
            )}

            {loading ? (
              <div
                style={{
                  width: "100vw",
                  height: "100vh",
                  backgroundColor: "#191919",
                  position: "fixed",
                  textAlign: "center",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    left: " calc( 50vw - 25px )",
                    top: "calc( 35vh)",
                  }}
                >
                  <ReactLoading
                    type="spin"
                    color="#f9b130"
                    height={50}
                    width={50}
                  />
                </div>
              </div>
            ) : (
              <>
                <ResponseCall />
                <Routes>
                  {!isUser ? (
                    <>
                      <Route
                        path="/Login"
                        element={
                          <Login isUser={isUser} setIsUser={setIsUser} />
                        }
                      />
                      <Route
                        path="/Signup"
                        element={
                          <Signup isUser={isUser} setIsUser={setIsUser} />
                        }
                      />
                      <Route
                        path="/Complete"
                        element={
                          <CompleteInfo isUser={isUser} setIsUser={setIsUser} />
                        }
                      />
                      <Route
                        path="/forgotPassword"
                        element={<ForgotPassword />}
                      />
                      <Route
                        path="/resetPassword/:resetToken"
                        element={<ResetPassword />}
                      />
                      <Route
                        path="*"
                        element={
                          <Login isUser={isUser} setIsUser={setIsUser} />
                        }
                      />
                    </>
                  ) : (
                    <>
                      <Route
                        path="/"
                        exact
                        element={
                          <ChatPage
                            isUser={isUser}
                            socket={socket}
                            user={userProfile}
                          />
                        }
                      />
                      <Route
                        path="/Complete"
                        element={
                          <CompleteInfo isUser={isUser} setIsUser={setIsUser} />
                        }
                      />
                      <Route
                        path="/videoCall"
                        element={<VideoCall socket={socket} />}
                      />
                      <Route
                        path="/voiceCall"
                        element={<VoiceCall socket={socket} />}
                      />
                      <Route path="/friends" element={<FriendList />} />
                      <Route
                        path="/Profile/:id"
                        element={
                          <Profile
                            isUser={isUser}
                            setIsUser={setIsUser}
                            socket={socket}
                          />
                        }
                      />
                      <Route
                        path="/Login"
                        element={
                          <Login isUser={isUser} setIsUser={setIsUser} />
                        }
                      />
                      <Route
                        path="/Signup"
                        element={
                          <Signup isUser={isUser} setIsUser={setIsUser} />
                        }
                      />

                      <Route path="*" element={<NotFound />} />
                    </>
                  )}
                </Routes>
              </>
            )}
          </main>
          <Grid item>
            {/* background author link for license  */}
            <div
              style={{
                fontSize: "10px",
                textAlign: "right",
              }}
            >
              <a href="http://www.freepik.com">
                Designed by pikisuperstar / Freepik
              </a>
            </div>

            <Footer />
          </Grid>
        </Router>
      </ContextProvider>
    </div>
  );
};

export default App;
