import React, { useEffect, useState } from "react";
import { forgotPassword } from "../utils/userApi";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import useStyles from "./useStyles";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const handleSubmit = (e) => {
    setDisableSubmit(true);
    e.preventDefault();
    forgotPassword(email).then((res) => setMessage(res.message));
    setDisableSubmit(false);
  };
  useEffect(() => {}, [message]);
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" paragraph>
          Reset password
        </Typography>
        {message === "ok" ? (
          <div className="alert largeAlert alert-primary ">
            Reset email has been sent successfully to:
            <span className="text-black-50"> {email}</span>
            <br /> Please check inbox and click on reset password link
          </div>
        ) : (
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  autoComplete="email"
                  name="email"
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Enter your email"
                  autoFocus
                  type="email"
                  placeholder="name@example.com"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              {message && (
                <div className="alert largeAlert alert-primary text-left  mt-2">
                  {message}
                </div>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disableSubmit}
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </Button>
            </Grid>
          </form>
        )}
      </div>
    </Container>
  );
};

export default ForgotPassword;
