import React from "react";
import { Link } from "react-router-dom";
import { Container, Typography } from "@material-ui/core";
import useStyles from "./useStyles";

const Footer = () => {
  const classes = useStyles();
  return (
    <>
      <footer className={classes.footer}>
        <Container maxWidth="sm">
          <Typography
            variant="body2"
            style={{ textAlign: "center", color: "#f9b130" }}
          >
            {"Copyright © "}
            <Link to="/" style={{ color: "inherit", textDecoration: "none" }}>
              X.O CHAT
            </Link>{" "}
            {new Date().getFullYear()}
            {"."}
          </Typography>
        </Container>
      </footer>
    </>
  );
};

export default Footer;
