import ReactLoading from "react-loading";
import {
  Avatar,
  Button,
  Container,
  Grid,
  Input,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { api, getProfile, updateAbout } from "../utils/userApi";
import useStyles from "./useStyles";
import { useDispatch, useSelector } from "react-redux";
import { useFriendRequest } from "./useProfile";
import { setAboutUser } from "../store/actions/userActions";

const Profile = ({ socket }) => {
  const params = useParams();
  const id = params.id;
  const dispatch = useDispatch();
  const [profileInformation, setProfileInformation] = useState({});
  const [isOwner, setIsOwner] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [editAbout, setEditAbout] = useState(false);
  const user = useSelector((state) => state.profile);
  const { firstName, lastName, image, dob, gender, about } = profileInformation;
  const classes = useStyles();
  const [newAbout, setNewAbout] = useState(about);
  const [newImage, setNewImage] = useState(null);
  const [loading, setLoading] = useState(true);

  const {
    handleAddRequest,
    handleDeleteFriend,
    handleAcceptRequest,
    handleImageInput,
  } = useFriendRequest(user, profileInformation, socket);
  const friendShip = user?.friendList?.find((friend) => friend._id === id);
  useEffect(() => {
    user._id === id ? setIsOwner(true) : setIsOwner(false);
    !isOwner
      ? getProfile(id)
          .then((res) => {
            res.message === "ok"
              ? setProfileInformation(res.user)
              : setAlertMessage(res.message);
          })
          .then(() => setLoading(false))
      : setProfileInformation(user);
  }, [user, isOwner, id]);

  const handleAboutUser = (e) => {
    e.preventDefault();
    dispatch(setAboutUser(newAbout));
    setEditAbout(false);
    updateAbout(newAbout);
  };
  const cancelAbout = (e) => {
    e.preventDefault();
    setNewAbout(about);
    setEditAbout(false);
  };
  return (
    <Container maxWidth="md">
      <div
        className={classes.paper}
        style={{ background: "#FFCEF3", padding: "0", overflow: "hidden" }}
      >
        {loading ? (
          <ReactLoading type="spin" color="#f9b130" height={50} width={50} />
        ) : (
          <Grid container direction="column">
            <Grid item className={classes.profilAvatarContainer} xs={12}>
              {isOwner ? (
                <div className={classes.imgEditContainer}>
                  <Avatar
                    className={classes.largeAvatar}
                    alt={firstName}
                    src={newImage ? newImage : image && `${api}/${image}`}
                  />{" "}
                  <label htmlFor="img-input" className={classes.editLabel}>
                    <CameraAltIcon />
                  </label>
                  <Input
                    type="file"
                    id="img-input"
                    accept="image/*"
                    style={{ visibility: "hidden" }}
                    onChange={(e) => handleImageInput(e, setNewImage)}
                  />
                </div>
              ) : (
                <Avatar
                  style={{ margin: "auto" }}
                  className={classes.largeAvatar}
                  alt={firstName}
                  src={image && `${api}/${image}`}
                />
              )}
              <Typography variant="h6">{firstName + " " + lastName}</Typography>
              {!isOwner ? (
                <>
                  {friendShip?.status === "sentRequest" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleDeleteFriend(e, "canceledRequest")}
                    >
                      Cancel
                    </Button>
                  )}
                  {friendShip?.status === "receivedRequest" && (
                    <>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) => handleAcceptRequest(e)}
                      >
                        Accept
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={(e) =>
                          handleDeleteFriend(e, "rejectedRequest")
                        }
                      >
                        Reject
                      </Button>
                    </>
                  )}

                  {friendShip?.status === "friend" && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleDeleteFriend(e, "unfriend")}
                    >
                      Unfriend
                    </Button>
                  )}
                  {(!friendShip || friendShip?.status === "unfriend") && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(e) => handleAddRequest(e)}
                    >
                      Add friend
                    </Button>
                  )}
                </>
              ) : (
                <></>
              )}
            </Grid>
            <Grid item xs={12}>
              <Grid container direction="column" style={{ padding: "1rem" }}>
                <Grid item xs={12} sm={10} lg={8}>
                  <Typography variant="h6" display="inline">
                    Full name:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    display="inline"
                    style={{ marginLeft: ".5rem" }}
                  >
                    {firstName + " " + lastName}
                  </Typography>
                </Grid>

                <Grid item xs={12} sm={10} lg={8}>
                  <Typography variant="h6" display="inline">
                    Gender:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    display="inline"
                    style={{ marginLeft: ".5rem" }}
                  >
                    {gender}
                  </Typography>{" "}
                </Grid>

                <Grid item xs={12} sm={10} lg={8}>
                  <Typography variant="h6" display="inline">
                    Date of Birth:
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    display="inline"
                    style={{ marginLeft: ".5rem" }}
                  >
                    {new Date(dob).toDateString()}
                  </Typography>{" "}
                </Grid>

                {isOwner || about ? (
                  <Grid container item xs={12} sm={10} lg={8}>
                    {" "}
                    <Grid item xs={10}>
                      <Typography variant="h6">About </Typography>{" "}
                      {!editAbout ? (
                        about && (
                          <Typography
                            variant="subtitle1"
                            className={classes.paper}
                            style={{
                              padding: ".5rem",
                              alignItems: "flex-start",
                              minHeight: "4rem",
                            }}
                          >
                            {about}{" "}
                          </Typography>
                        )
                      ) : (
                        <form onSubmit={(e) => handleAboutUser(e)}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            variant="outlined"
                            style={{ width: "100%" }}
                            minRows={5}
                            defaultValue={about}
                            placeholder="Write a brief about yourself"
                            onChange={(e) => setNewAbout(e.target.value)}
                          />
                          <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                            onSubmit={(e) => handleAboutUser(e)}
                          >
                            Save
                          </Button>
                          <Button
                            variant="outlined"
                            color="primary"
                            style={{ marginLeft: ".5rem" }}
                            onClick={(e) => cancelAbout(e)}
                          >
                            Cancel
                          </Button>
                        </form>
                      )}
                    </Grid>{" "}
{isOwner && <Grid item xs={2}>
                      <Button onClick={(e) => setEditAbout(!editAbout)}>
                        {about ? <EditIcon /> : <AddIcon />}
                      </Button>
                    </Grid>}
                  </Grid>
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Grid>
        )}
      </div>
    </Container>
  );
};

export default Profile;
