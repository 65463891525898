import {
  ACCEPT_FRIEND_REQUEST,
  ADD_DATA,
  ADD_FRIEND_REQUEST,
  CLEAR_STORE,
  RECEIVE_FRIEND_REQUEST,
  REMOVE_FRIEND_REQUEST,
  SET_ABOUT_USER,
  SET_NEW_IMAGE,
  SET_ONLINE_STATUS,
} from "../actions/userActions";

export default function profile(state = {}, action) {
  switch (action.type) {
    case ADD_DATA:
      return {
        ...action.user,
      };
    case SET_NEW_IMAGE:
      return {
        ...state,
        image: action.image,
      };
    case SET_ONLINE_STATUS:
      return {
        ...state,
        friendList: state.friendList.map((friend) =>
          friend._id === action.id
            ? { ...friend, isOnline: action.isOnline }
            : friend
        ),
      };
    case SET_ABOUT_USER:
      return {
        ...state,
        about: action.about,
      };
    case ADD_FRIEND_REQUEST:
      return state.friendList.find((friend) => friend._id === action.friend._id)
        ? {
            ...state,
            friendList: state.friendList.map((friend) =>
              friend._id === action.friend._id
                ? {
                    ...friend,
                    status: "sentRequest",
                  }
                : friend
            ),
          }
        : {
            ...state,
            friendList: state.friendList.concat({
              ...action.friend,
              status: "sentRequest",
            }),
          };
    case RECEIVE_FRIEND_REQUEST:
      return state.friendList.find((friend) => friend._id === action.friend._id)
        ? {
            ...state,
            friendList: state.friendList.map((friend) =>
              friend._id === action.friend._id
                ? {
                    ...friend,
                    status: "receivedRequest",
                  }
                : friend
            ),
          }
        : {
            ...state,
            friendList: state.friendList.concat(action.friend),
          };
    case REMOVE_FRIEND_REQUEST:
      return {
        ...state,
        friendList: state.friendList.map((friend) =>
          friend._id === action.id
            ? {
                ...friend,
                status: "unfriend",
              }
            : friend
        ),
      };
    case ACCEPT_FRIEND_REQUEST:
      return {
        ...state,
        friendList: state.friendList.map((friend) =>
          friend._id === action.id
            ? {
                ...friend,
                status: "friend",
                conversationId: action.conversationId,
              }
            : friend
        ),
      };

    case CLEAR_STORE:
      return {};
    default:
      return state;
  }
}
