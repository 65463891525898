import { alpha, makeStyles } from "@material-ui/core";
import bgImg from "../utils/images/blob3.svg";
import chatImg from "../utils/images/4853433.jpg";
import appBg from "../utils/images/3506328.jpg";
const primaryGlassBg = "rgb(254 176 1 / 85%)";
const secondaryGlassBg = "rgb(231 232 237 / 82%)";
// const primaryBg = "#fdb001";
const primaryBg = "#f9b130";
const secondaryBg = "#191919";
const primaryText = "#fff";
const warningColor = "#d33c2f";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    backgroundImage: `url(${appBg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    justifyContent: "space-between",
  },
  main: {
    // marginTop: theme.spacing(8),
    // marginBottom: theme.spacing(2),
    position: "relative",
    marginTop: "56px",
    [theme.breakpoints.up("sm")]: {
      marginTop: "64px",
    },
  },
  notFound: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  linkToHome: { color: "#c65547", fontWeight: "bold" },
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "auto",
    // backgroundColor: "#00334E",
    backgroundColor: secondaryBg,
  },
  paper: {
    margin: theme.spacing(2),
    padding: " 2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    background: primaryGlassBg,
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 5px )",
    webkitBackdropFilter: " blur( 5px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  //! ================================================================
  //?   navbar
  header: {
    // backgroundColor: "#00334E",
    backgroundColor: secondaryBg,
    color: primaryText,
  },
  toolbar: {
    display: "flex",
    justifyContent: "space-between",
  },
  appName: {},
  grow: {
    flexGrow: 1,
  },
  searchDiv: {
    position: "absolute",
    backgroundColor: primaryBg,
    maxHeight: "50vh",
    minHeight: "25vh",
    display: "block",
    minWidth: "100%",
    left: "0px",
    zIndex: "1",
    padding: "10px",
    marginTop: "5px",
    borderRadius: "5px",
    overflow: "auto",
    cursor: "pointer",
  },

  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    // display: "none",
    // [theme.breakpoints.up("sm")]: {
    //   display: "block",
    // },
    color: primaryBg,
    cursor: "pointer",
  },
  navSearch: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    display: "inline-block",
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    // marginLeft: 0,
    // width: "100%",
    marginLeft: theme.spacing(1),
    width: "auto",
    [theme.breakpoints.up("sm")]: {
      marginLeft: theme.spacing(3),
      width: "auto",
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  navLink: {
    textDecoration: "none",
    color: "inherit",
  },
  notificationDate: {
    textAlign: "end",
    paddingLeft: "1rem",
    fontSize: ".75rem",
    color: "#666666",
  },
  mobileList: {
    position: "absolute",
    top: "56px",
    left: "0",
    width: "100vw",
    height: "calc(100vh - 56px)",
    backgroundColor: secondaryBg,
    color: primaryBg,
    zIndex: "1",
    paddingRight: "1rem",
    "&>li": {
      borderBottom: "2px solid #1e1e1e",
      paddingTop: "1rem",
      paddingBottom: "1rem",
      cursor: "pointer",
    },
  },
  mobileIcons: {
    justifyContent: "end",
    paddingRight: "1rem",
  },
  logoutBtn: {
    backgroundColor: warningColor,
    separator: { padding: "0 .2rem", fontSize: "1.5rem" },
    color: primaryText,
  },
  //! ================================================================
  inputRoot: {
    color: "inherit",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("md")]: {
      width: "20ch",
    },
  },
  inputInputChat: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create("width"),
    width: "100%",
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "flex",
    },
  },
  sectionMobile: {
    display: "flex",
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  //? profile
  largeAvatar: {
    height: "7rem",
    width: "7rem",
    border: "2px solid #fff",
  },
  imgEditContainer: {
    position: "relative",
    borderRadius: "50%",
    width: "7rem",
    height: "7rem",
    margin: "auto",
  },
  editLabel: {
    border: "2px solid #fff",
    width: "2.5rem",
    height: "2.5rem",
    borderRadius: "0.5rem",
    bottom: "0",
    right: "0",
    padding: ".5rem",
    cursor: "pointer",
    margin: "auto",
    position: "absolute",
    backgroundColor: "rgb(227 133 228 / 66%)",
  },
  profilAvatarContainer: {
    textAlign: "center",
    background: "#CABBE9",
    padding: "2rem",
  },
  // !==========================================================

  //? chatPage
  chatPageContainer: {
    background: secondaryGlassBg,
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 4px )",
    WebkitBackdropFilter: "blur( 4px )",
    borderRadius: "10px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    width: "95%",
    height: "90%",
    marginRight: "2.5%",
    marginTop: "20px",
    padding: "0",
  },
  chatPageHeader: {
    color: "#180687",
    backgroundImage: `url(${bgImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "11rem",
    height: "8rem",
    lineHeight: "2.5",
  },
  chatContainer: {
    borderRadius: theme.shape.borderRadius,
    margin: "auto",
    padding: "1rem",
    minHeight: "70vh",
    flexDirection: "column",
    [theme.breakpoints.up("md")]: {
      flexDirection: "row",
    },
    animation: `$myEffect 1s ease`,
  },
  sideList: {
    borderRadius: "25px 0 0 25px",
    padding: "1.5rem",
    minHeight: "100%",
    backgroundColor: secondaryBg,
    color: primaryText,
    maxHeight: "70vh",
    overflow: "auto",
  },
  friendList: {
    paddingTop: "1rem",
    cursor: "pointer",
  },
  chatBox: {
    height: "70vh",
    padding: "1rem",
  },
  conversationBox: {
    borderRadius: "5px",
    height: "70vh",
    backgroundColor: primaryBg,
    // backgroundImage: `url(${chatImg})`,
    // backgroundRepeat: "round",
  },
  loadingChatContainer: {
    height: "50vh",
    margin: "auto",
    position: "relative",
    "&>div": {
      position: "absolute",
      left: "45%",
      top: "40%",
    },
  },
  relative: { position: "relative" },
  absoluteBadge: { position: "absolute", top: "0", left: "0" },
  messagesBox: { overflow: "auto", width: "100%" },
  // chatMessage: {
  //   // backgroundColor: "#2D4263",
  //   background: "rgb(255 255 255 / 2%)",
  //   boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
  //   backdropFilter: "blur( 3px )",
  //   WebkitBackdropFilter: "blur( 3px )",
  //   borderRadius: "0  20px",
  //   border: "1px solid rgba( 255, 255, 255, 0.18 )",
  //   width: "fit-content",
  //   maxWidth: "40%",
  //   margin: "5px",
  //   padding: "1rem",
  // },
  friendInfoBox: {
    backgroundColor: secondaryBg,
    color: primaryBg,
    borderRadius: "10px",
    padding: "1rem",
    marginBottom: "1rem",
    position: "relative",
  },
  CloseChatIcon: {
    position: "absolute",
    top: "0",
    right: "0",
    color: primaryBg,
  },
  typeMessage: { width: "70%", color: primaryText },
  userMessage: {
    color: "#413c3c",
    background: "rgb(255 208 208 / 41%)",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 3px )",
    WebkitBackdropFilter: "blur( 3px )",
    borderRadius: "0  20px",
    width: "fit-content",
    maxWidth: "40%",
    margin: "5px",
    padding: "1rem",
  },
  friendMessage: {
    color: "#ffe8e5",
    background: "rgb(65 60 60 / 40%)",
    boxShadow: "0 8px 32px 0 rgba( 31, 38, 135, 0.37 )",
    backdropFilter: "blur( 3px )",
    WebkitBackdropFilter: "blur( 3px )",
    borderRadius: "0  20px",
    border: "1px solid rgba( 255, 255, 255, 0.18 )",
    width: "fit-content",
    maxWidth: "40%",
    margin: "5px",
    padding: "1rem",
  },
  dateDisplay: {
    color: "#9b9b9b",
    fontSize: ".7rem",
  },
  iconBtn: {
    color: primaryText,
    padding: "0",
    "&:disabled": {
      color: primaryText,
      filter: "opacity(.1)",
    },
  },

  // =========================================================
  // signup
  formControlInput: {
    width: "100%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    border: "1px solid #0000003b",
    padding: ".5rem",
  },
  // =========================================================
  //responseCall
  answerCallIcon: { backgroundColor: "#3a7e32", color: primaryText },
  rejectCallIcon: { backgroundColor: warningColor, color: primaryText },
  responseCallDiv: {
    height: "100vh",
    position: "fixed",
    zIndex: "3",
    backgroundColor: "#191919f2",
    width: "100vw",
    top: "0",
  },
  responseCallContainer: {
    padding: "2rem",
    position: "absolute",
    top: "30vh",
    borderRadius: "10px",
    width: "90vw",
    marginLeft: "5vw",
    textAlign: "center",
    background: "linear-gradient(145deg, #e49e01, #ffbc01)",
    boxShadow: "5px 5px 15px 0px #000000, -2px -2px 7px #ffff02",
  },
  // ===========================================================
  // videoCall
  videoCallGrid: { margin: "1rem 0", width: "100%" },
  videoContainer: {
    maxWidth: "50%",
    "&>.myVideo": {
      maxWidth: "100%",
      border: `10px solid ${primaryBg}`,
    },
    "&>.userVideo": {
      maxWidth: "100%",
      border: "10px solid #191919",
    },
  },
  videoCallStatus: {
    textAlign: "center",
    padding: "5px",
    background: "#191919cf",
    marginBottom: "2rem",
    color: primaryText,
  },
  endVideoCallBtn: {
    backgroundColor: warningColor,
    color: primaryText,
  },
  // ===============================================================
  //voiceCall
  voiceCallContainer: {
    width: "50%",
    borderRadius: "50px",
    background: "#191919f5",
    boxShadow: "20px 20px 60px #0d0d0d,-20px -20px 60px #252525",
    height: "70vh",
    margin: "1rem auto",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  onCallInfo: { margin: "auto", color: primaryBg },
  voiceCallStatus: {
    textAlign: "center",
    marginTop: "2rem",
    color: primaryText,
  },
  endVoiceCallDiv: {
    padding: "0",
    textAlign: "center",
    marginBottom: "2rem",
  },
  endVoiceCallBtn: { backgroundColor: warningColor, color: primaryText },
  // ==================================================================
  // ! friendListPage
  friendListHeader: {
    backgroundColor: secondaryBg,
    color: primaryBg,
    padding: "1rem .5rem",
    borderRadius: "1rem 1rem 0 0",
  },
  noFriends: { padding: "6rem 1rem", textAlign: "center" },
  searchNewSection: {
    background: secondaryBg,
    position: "fixed",
    width: "100%",
    minHeight: "100%",
    top: "0",
    left: "0",
    padding: "1rem",
    borderRadius: "10px",
    animation: `$searchEffect 1s ease`,
  },
  closeSearch: { padding: "0", color: primaryBg, marginBottom: "10px" },
  noResult: {
    color: primaryBg,
    textAlign: "center",
    padding: "3rem 0",
  },
  // ==================================================================
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
      transform: "translateX(-100%)",
    },
    "100%": {
      opacity: 1,
      transform: "translateX(0)",
    },
  },
  "@keyframes searchEffect": {
    "0%": {
      transform: "translateY(100%)",
    },
    "100%": {
      transform: "translateY(0)",
    },
  },
}));

export default useStyles;
