export const displayDate = (date) => {
  const newDate = new Date(date);
  const dateDifference = (new Date() - newDate) / (60000 * 60 * 24);
  const time = `at ${("0" + newDate.getHours()).slice(-2)}:${(
    "0" + newDate.getMinutes()
  ).slice(-2)}`;
  const displayTime = () => {
    const timeDifference = (new Date() - newDate) / (60000 * 60);
    if (timeDifference < 1 / 3600) {
      return "now";
    } else if (timeDifference < 1 / 60) {
      return `${(timeDifference * 3600).toFixed(0)}s`;
      // ${
      //   (timeDifference * 3600).toFixed(0) === "1" ? "second" : "seconds"
      // }`;
    } else if (timeDifference < 1) {
      return `${(timeDifference * 60).toFixed(0)}min`;
      // ${
      //   (timeDifference * 60).toFixed(0) === "1" ? "minute" : "minutes"
      // }`;
    } else if (timeDifference < 2) {
      return "1h";
    } else if (timeDifference < 3) {
      return "2h";
    } else if (timeDifference < 12) {
      return time;
    } else {
      return "Today";
    }
  };
  switch (true) {
    case dateDifference < 1:
      return displayTime();
    case dateDifference < 2:
      return " Yesterday";
    case dateDifference < 3:
      return "2 days";
    default:
      return newDate.toDateString();
  }
};
