import React, { useContext, useEffect } from "react";
import { Avatar, Button, Container, Grid, Typography } from "@material-ui/core";
import useStyles from "./useStyles";
import { CallContext } from "./CallContext";
import { useNavigate } from "react-router-dom";
import busy from "../utils/ringtones/busy-signal-sound.mp3";
import disconnected from "../utils/ringtones/phone-disconnected.mp3";
import ringing from "../utils/ringtones/ringing.mp3";
import { api } from "../utils/userApi";

const VoiceCall = ({ socket }) => {
  const navigate = useNavigate();
  const {
    call,
    callAccepted,
    callEnded,
    userMedia,
    endCall,
    callStatus,
    handleCallStatus,
  } = useContext(CallContext);

  useEffect(() => {
    callEnded && navigate("/");
  }, [callEnded, navigate]);
  useEffect(() => {}, [call]);

  const classes = useStyles();
  return (
    <Container maxWidth="md">
      {callAccepted ? (
        !callEnded && <audio playsInline ref={userMedia} autoPlay />
      ) : (
        <>
          {callStatus === "ringing" && (
            <audio playsInline src={ringing} loop autoPlay />
          )}
          {callStatus === "busy" && (
            <audio playsInline loop src={busy} autoPlay />
          )}

          {(callStatus === "rejected" || callStatus === "noResponse") && (
            <audio playsInline src={disconnected} loop autoPlay />
          )}
        </>
      )}
      <Grid
        container
        direction="column"
        wrap="nowrap"
        justifyContent="space-between"
        spacing={2}
        className={classes.voiceCallContainer}
      >
        <Grid item className={classes.onCallInfo}>
          <Avatar
            src={call && `${api}/${call?.image}`}
            alt={call?.name}
            className={classes.largeAvatar}
            style={{ margin: "1rem auto" }}
          />
          <Typography variant="h5" style={{ textAlign: "center" }}>
            {call?.name}
          </Typography>
          {!callAccepted && (
            <Typography variant="h5" className={classes.voiceCallStatus}>
              {handleCallStatus()}
            </Typography>
          )}
        </Grid>
        <Grid item className={classes.endVoiceCallDiv}>
          <Button
            variant="contained"
            className={classes.endVoiceCallBtn}
            onClick={() => {
              navigate("/");
              endCall();
            }}
          >
            End Call
          </Button>
        </Grid>{" "}
      </Grid>
    </Container>
  );
};

export default VoiceCall;
