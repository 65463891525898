import axios from "axios";
export const api = "https://xochat.onrender.com/";
// export const api = "https://xo-chat-app.herokuapp.com";
// export const api = "http://localhost:5001";
// export const api =
//   "https://xochat-env-2.eba-mqjicpf6.us-east-1.elasticbeanstalk.com/";

let token = localStorage.accessToken && `Bearer ${localStorage.accessToken}`;
let headers = {
  Authorization: token,
};

// create new user (signup)
export const createUser = async (body) => {
  return await axios("/user/signup", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    //withCredentials: true,
    baseURL: api,
    data: JSON.stringify(body),
  })
    .then((res) => res.data)
    .then(async (res) => {
      if (res.message === "ok") {
        localStorage.accessToken = await res.accessToken;
        token = `Bearer ${localStorage.accessToken}`;
        headers = { Accept: "application/json", Authorization: token };
      }
      return res;
    });
};
// ===========================================================
// login
export const login = async (body) => {
  return await axios("/user/login", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    //withCredentials: true,
    baseURL: api,
    data: JSON.stringify(body),
  })
    .then((res) => {
      return res.data;
    })
    .then(async (res) => {
      if (res.message === "ok") {
        localStorage.accessToken = await res.accessToken;
        token = `Bearer ${localStorage.accessToken}`;
        headers = { Accept: "application/json", Authorization: token };
      }
      return res;
    })
    .catch((err) => err);
};
// ======================================================
export const authorize = async () => {
  if (token) {
    return await axios("/user/authorize", {
      method: "GET",
      headers: {
        ...headers,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      //withCredentials: true,
      baseURL: api,
    })
      .then((res) => res.data)
      .catch((err) => err);
  } else {
    return { message: "please login first" };
  }
};

// ======================================================
// logout
export const handleLogout = async () => {
  localStorage.clear();
  token = null;
  headers = { Authorization: token };
  window.location.reload();
};
//======================================================
// forgot password
export const forgotPassword = async (email) => {
  return await axios("/user/forgotPassword", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ email: email }),
  })
    .then((res) => res.data)
    .catch((err) => err);
};

// ========================================================
// reset password
export const resetPassword = async (password, token) => {
  return await axios("/user/resetPassword", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ password, token }),
  })
    .then((res) => res.data)
    .catch((err) => err);
};

// ========================================================
// get profile information
export const getProfile = async (id) => {
  return await axios("/profile", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ id }),
  })
    .then((res) => res.data)
    .catch((err) => err);
};
// ==========================================================
// ========================================================
// get profile information
export const getProfileByName = async (query) => {
  return await axios("/profile/query", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ query }),
  })
    .then((res) => res.data)
    .catch((err) => err);
};
// ==========================================================
// change image
export const saveNewImage = async (image) => {
  let formData = new FormData();
  formData.append("image", image, "image.*");
  return await axios("/profile/image", {
    method: "POST",
    headers: {
      ...headers,
      // "Content-Type": "multipart/form-data",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: formData,
  }).then((res) => res.data);
};
// ==========================================================
// update about user
export const updateAbout = async (about) => {
  return await axios("/profile/about", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ about }),
  }).then((res) => res.data);
};
// ==========================================================
//add friend request
export const fetchFriendRequest = async (user, friend) => {
  return await axios("/friends/add", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ user, friend }),
  }).then((res) => res.data);
};

//remove friend/friend request
export const fetchRemoveFriend = async (user, friendId) => {
  return await axios("/friends/delete", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ user, friendId }),
  }).then((res) => res.data);
};
//accept friend request
export const fetchAcceptFriend = async (user, friendId) => {
  return await axios("/friends/accept", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ user, friendId }),
  }).then((res) => res.data);
};
export const fetchResetNewNotification = async (userId) => {
  return await axios("/notifications/resetNew", {
    method: "PUT",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ userId }),
  }).then((res) => res.data);
};
export const fetchSetIsRead = async (userId, date) => {
  return await axios("/notifications/setIsRead", {
    method: "PUT",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ userId, date }),
  }).then((res) => res.data);
};
//!===============================================================
//?send message

export const postNewMessage = async (conversationId, newMessage) => {
  return await axios("/conversations/newMessage", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ conversationId, newMessage }),
  }).then((res) => res.data);
};
//!================================================================
//? set seen messages

export const fetchSeenMessages = async (conversationId, senderId) => {
  return await axios("/conversations/setSeen", {
    method: "POST",
    headers: {
      ...headers,
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    //withCredentials: true,
    baseURL: api,

    data: JSON.stringify({ conversationId, senderId }),
  }).then((res) => res.data);
};
