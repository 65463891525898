import { Menu, MenuItem } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  resetNewNotification,
  setIsReadNotification,
} from "../store/actions/notificationAction";
import { clearStore } from "../store/actions/userActions";
import { displayDate } from "../utils/displayDate";
import {
  fetchResetNewNotification,
  fetchSetIsRead,
  handleLogout,
} from "../utils/userApi";
import useStyles from "./useStyles";

export const useMenu = (
  isUser,
  setIsUser,
  setAnchorEl,
  anchorEl,
  setIsMenuButtonOpen
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const classes = useStyles();
  const userProfile = useSelector((state) => state.profile);

  //  ? profile menu render

  //! handle logout

  const handleLogoutBtn = () => {
    handleProfileMenuClose();
    handleLogout()
      .then(() => {
        setIsUser(false);
      })
      .then(() => {
        navigate("/login");
        dispatch(clearStore());
      });
  };

  const handleNavToFriendList = () => {
    handleProfileMenuClose();
    navigate("/friends");
  };
  //===============================================================
  //! navigate to user profile
  const handleUserProfile = () => {
    handleProfileMenuClose();
    navigate(`/profile/${userProfile._id}`);
  };

  //! close profile menu function
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
    setIsMenuButtonOpen(false);
  };

  const profileMenuId = "profile-menu";
  const isProfileMenuOpen = Boolean(anchorEl?.id === "profile");
  //! profile menu
  const renderProfileMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={profileMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isProfileMenuOpen}
      onClose={handleProfileMenuClose}
    >
      <div>
        <MenuItem onClick={handleUserProfile}>Profile</MenuItem>
        <MenuItem onClick={() => handleNavToFriendList()}>My Friends</MenuItem>
        <MenuItem onClick={handleProfileMenuClose} disabled>
          My account
        </MenuItem>
        <MenuItem onClick={handleLogoutBtn}>Logout</MenuItem>
      </div>
    </Menu>
  );

  //  ? notifications menu render
  //? new notifications
  const userNotification = useSelector((state) => state.notifications).sort(
    function (a, b) {
      return b.date - a.date;
    }
  );
  const totalNewNotifications = userNotification.filter((notification) => {
    return notification.new === true;
  }).length;

  const notificationMenuId = "notifications-menu";
  //! handle on click notification
  const handleNotification = (targetId, date) => {
    dispatch(setIsReadNotification(date));
    fetchSetIsRead(userProfile._id, date);
    handleNotificationsMenuClose();
    navigate(`/profile/${targetId}`);
  };
  //! close notification menu function
  const handleNotificationsMenuClose = () => {
    setAnchorEl(null);
    setIsMenuButtonOpen(false);
    dispatch(resetNewNotification());
    fetchResetNewNotification(userProfile._id);
  };
  const isNotificationMenuOpen = Boolean(anchorEl?.id === "notifications");

  //! notifications menu
  const renderNotificationsMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={notificationMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isNotificationMenuOpen}
      onClose={handleNotificationsMenuClose}
    >
      <div>
        {userNotification.length === 0 ? (
          <div
            style={{ height: "5rem", padding: "2rem" }}
            onClick={handleNotificationsMenuClose}
          >
            There is no notifications.
          </div>
        ) : (
          userNotification.map((notification) => (
            <MenuItem
              key={notification.date}
              className={classes.notificationMenuItem}
              onClick={() =>
                handleNotification(notification._id, notification.date)
              }
              style={{
                backgroundColor: notification.new
                  ? "#ff9f9f"
                  : notification.isRead
                  ? "#fff"
                  : "#f7d1d1",
                borderBottom: "1px solid #cfcfcf",
                justifyContent: "space-between",
              }}
            >
              <p>
                {notification.status === "receivedRequest"
                  ? `${notification.firstName} ${notification.lastName} sent you friend request`
                  : notification.status === "acceptedRequest"
                  ? `${notification.firstName} ${notification.lastName} accepted your friend request`
                  : notification.status === "deletedFriend"
                  ? notification.type === "canceledRequest"
                    ? `${notification.firstName} ${notification.lastName} canceled his friend request`
                    : notification.type === "rejectedRequest"
                    ? `${notification.firstName} ${notification.lastName} rejected your friend request`
                    : notification.type === "unfriend" &&
                      `${notification.firstName} ${notification.lastName} unfriend you`
                  : ""}
              </p>
              <p className={classes.notificationDate}>
                {displayDate(notification.date)}
              </p>
            </MenuItem>
          ))
        )}
      </div>
    </Menu>
  );
  //! render new chat messages menu
  //? new chat messages
  const conversations = useSelector((state) => state.conversations).sort(
    function (a, b) {
      return b.messages[b.messages?.length - 1]?.date >
        a.messages[a.messages?.length - 1]?.date
        ? 1
        : -1;
    }
  );
  const unseenMessages = conversations?.map((conversation) => {
    const filteredMessages = conversation.messages?.filter(
      (message) =>
        message.seen === false && message.senderId !== userProfile._id
    );
    return {
      conversationId: conversation._id,
      unseen: filteredMessages,
    };
  });
  let totalUnseen = 0;
  unseenMessages?.map(
    (conversation) => (totalUnseen += conversation.unseen.length)
  );

  const messagesMenuId = "messages-menu";
  const isMessagesMenuOpen = Boolean(anchorEl?.id === "messages");
  const handleMessagesMenuClose = () => {
    setAnchorEl(null);
    setIsMenuButtonOpen(false);
  };
  const getFullName = (user) => {
    return user.firstName + " " + user.lastName;
  };
  const renderMessagesMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id={messagesMenuId}
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMessagesMenuOpen}
      onClose={handleMessagesMenuClose}
    >
      {totalUnseen === 0 ? (
        <div
          style={{ height: "5rem", padding: "2rem" }}
          onClick={handleMessagesMenuClose}
        >
          There is no new messages.
        </div>
      ) : (
        unseenMessages.map(
          (conversation) =>
            conversation.unseen.length > 0 && (
              <MenuItem
                key={conversation.conversationId}
                onClick={() => {
                  navigate("/", { state: conversation.conversationId });
                  handleMessagesMenuClose();
                }}
              >
                {isUser &&
                  `You have ${conversation.unseen.length} new
          messages from ${getFullName(
            userProfile?.friendList?.find(
              (friend) => friend.conversationId === conversation.conversationId
            )
          )}`}
              </MenuItem>
            )
        )
      )}
    </Menu>
  );

  return {
    renderNotificationsMenu,
    notificationMenuId,
    totalNewNotifications,
    renderProfileMenu,
    profileMenuId,
    renderMessagesMenu,
    messagesMenuId,
    totalUnseen,
    handleNavToFriendList,
    handleUserProfile,
  };
};
