//! imports
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  InputBase,
  Badge,
  Grid,
  Avatar,
  List,
  ListItem,
  Button,
} from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import SearchIcon from "@material-ui/icons/Search";
import AccountCircle from "@material-ui/icons/AccountCircle";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MessageIcon from "@material-ui/icons/Message";
// import MoreIcon from "@material-ui/icons/MoreVert";
import useStyles from "./useStyles";
import { useMenu } from "./useMenus";
import { api, getProfileByName, handleLogout } from "../utils/userApi";

//! component function
const Nav = ({ isUser, setIsUser }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchResult, setSearchResult] = useState(null);
  const [isMenuButtonOpen, setIsMenuButtonOpen] = useState(false);
  const navigate = useNavigate();

  //! handle menus rendering
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const {
    renderNotificationsMenu,
    notificationMenuId,
    totalNewNotifications,
    renderProfileMenu,
    profileMenuId,
    renderMessagesMenu,
    messagesMenuId,
    totalUnseen,
    handleUserProfile,
    handleNavToFriendList,
  } = useMenu(isUser, setIsUser, setAnchorEl, anchorEl, setIsMenuButtonOpen);
  const handleSearch = async (e) => {
    e.preventDefault();
    e.target.value.trim() !== ""
      ? await getProfileByName(e.target.value).then((res) =>
          setSearchResult(res.result)
        )
      : setSearchResult(null);
  };
  const handleNavToHome = () => {
    navigate("/");
    setIsMenuButtonOpen(false);
  };
  const handleNavToProfile = (e, id) => {
    e.preventDefault();
    navigate(`/profile/${id}`);
    setIsSearchResultOpen(false);
    setIsMenuButtonOpen(false);
  };
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false);

  const handleBlur = (e) => {
    setTimeout(() => setIsSearchResultOpen(false), 700);
  };

  const searchSection = (
    <div
      className={classes.search}
      onFocus={() => setIsSearchResultOpen(true)}
      onBlur={(e) => handleBlur(e)}
    >
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        placeholder="Find new friends…"
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        inputProps={{ "aria-label": "search" }}
        onChange={(e) => handleSearch(e)}
      />
      {searchResult && searchResult.length > 0 && isSearchResultOpen && (
        <div className={classes.searchDiv}>
          {searchResult.map((friend) => (
            <Grid
              container
              direction="row"
              alignItems="center"
              wrap="nowrap"
              spacing={2}
              key={friend._id}
            >
              <Grid item>
                <Avatar alt={friend.name} src={`${api}/${friend.image}`} />
              </Grid>
              <Grid
                item
                onClick={(e) => {
                  handleNavToProfile(e, friend._id);
                  setIsMenuButtonOpen(false);
                }}
              >
                <Typography
                  variant="subtitle1"
                  style={{ color: "#000" }}
                >{`${friend.name}`}</Typography>
              </Grid>
            </Grid>
          ))}
        </div>
      )}
    </div>
  );
  const navIcons = (
    <>
      {" "}
      <IconButton
        aria-label="show new messages"
        color="inherit"
        aria-controls={messagesMenuId}
        onClick={handleProfileMenuOpen}
        id="messages"
      >
        <Badge
          badgeContent={totalUnseen}
          overlap="rectangular"
          color="secondary"
        >
          <MessageIcon />
        </Badge>
      </IconButton>
      <IconButton
        aria-label="show new notifications"
        color="inherit"
        aria-controls={notificationMenuId}
        onClick={handleProfileMenuOpen}
        id="notifications"
      >
        <Badge
          badgeContent={totalNewNotifications}
          overlap="rectangular"
          color="secondary"
        >
          <NotificationsIcon />
        </Badge>
      </IconButton>
    </>
  );

  //? return jsx
  return (
    <AppBar className={classes.header} position="fixed">
      <Toolbar className={classes.toolbar}>
        <Grid item>
          <Typography
            className={classes.title}
            variant="h6"
            noWrap
            onClick={() => navigate("/")}
          >
            X.O CHAT
          </Typography>
        </Grid>
        <Grid item>
          <div className={classes.navSearch}>{searchSection}</div>
        </Grid>
        <Grid item>
          <div className={classes.grow} />
          {isUser ? (
            <>
              <div className={classes.sectionDesktop}>
                {navIcons}

                <IconButton
                  edge="end"
                  aria-label="account of current user"
                  aria-controls={profileMenuId}
                  aria-haspopup="true"
                  onClick={handleProfileMenuOpen}
                  color="inherit"
                  id="profile"
                >
                  <AccountCircle />
                </IconButton>
              </div>
              <div className={classes.sectionMobile}>
                <IconButton
                  edge="start"
                  // className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => setIsMenuButtonOpen((prev) => !prev)}
                >
                  <Badge
                    overlap="rectangular"
                    badgeContent={totalUnseen + totalNewNotifications}
                    color="secondary"
                  >
                    <MenuIcon />
                  </Badge>
                </IconButton>
                {isMenuButtonOpen && (
                  <List className={classes.mobileList}>
                    {" "}
                    <ListItem className={classes.mobileIcons}>
                      {navIcons}
                    </ListItem>
                    <ListItem>Search {searchSection}</ListItem>
                    <ListItem
                      onClick={() => {
                        handleUserProfile();
                        setIsMenuButtonOpen(false);
                      }}
                    >
                      My profile
                    </ListItem>
                    <ListItem
                      onClick={() => {
                        handleNavToFriendList();
                        setIsMenuButtonOpen(false);
                      }}
                    >
                      Friends/Add Friends
                    </ListItem>
                    <ListItem onClick={() => handleNavToHome()}>
                      Chatting
                    </ListItem>
                    <Button
                      fullWidth
                      variant="contained"
                      className={classes.logoutBtn}
                      onClick={() => handleLogout()}
                    >
                      logout
                    </Button>
                  </List>
                )}
              </div>
              {renderProfileMenu}
              {renderNotificationsMenu}
              {renderMessagesMenu}
            </>
          ) : (
            <>
              <Link className={classes.navLink} to="/login">
                Login
              </Link>
              <span className={classes.separator}> | </span>
              <Link className={classes.navLink} to="/signup">
                Signup
              </Link>
            </>
          )}
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default Nav;
