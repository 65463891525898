export const ADD_DATA = "ADD_DATA";
export const CLEAR_STORE = "CLEAR_STORE";
export const ADD_FRIEND_REQUEST = "ADD_FRIEND_REQUEST";
export const RECEIVE_FRIEND_REQUEST = "RECEIVE_FRIEND_REQUEST";
export const REMOVE_FRIEND_REQUEST = "REMOVE_FRIEND_REQUEST";
export const ACCEPT_FRIEND_REQUEST = "ACCEPT_FRIEND_REQUEST";
export const SET_NEW_IMAGE = "SET_NEW_IMAGE";
export const SET_ONLINE_STATUS = "SET_ONLINE_STATUS";
export const SET_ABOUT_USER = "SET_ABOUT_USER";
export const addProfileData = (user) => {
  return {
    type: ADD_DATA,
    user,
  };
};
export const changeImage = (image) => {
  return {
    type: SET_NEW_IMAGE,
    image,
  };
};
export const changeOnlineStatus = (id, isOnline) => {
  return {
    type: SET_ONLINE_STATUS,
    id,
    isOnline,
  };
};
export const setAboutUser = (about) => {
  return {
    type: SET_ABOUT_USER,
    about,
  };
};
export const clearStore = () => {
  return {
    type: CLEAR_STORE,
  };
};
export const addFriendRequest = (friend) => {
  return {
    type: ADD_FRIEND_REQUEST,
    friend,
  };
};
export const receiveFriendRequest = (friend) => {
  return {
    type: RECEIVE_FRIEND_REQUEST,
    friend,
  };
};
export const removeFriendRequest = (id) => {
  return {
    type: REMOVE_FRIEND_REQUEST,
    id,
  };
};
export const acceptFriendRequest = (id, conversationId) => {
  return {
    type: ACCEPT_FRIEND_REQUEST,
    id,
    conversationId,
  };
};
