import {
  Container,
  Grid,
  Typography,
  IconButton,
  Avatar,
} from "@material-ui/core";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CallContext } from "./CallContext";
import VideoCallIcon from "@material-ui/icons/VideoCall";
import CallIcon from "@material-ui/icons/Call";
import CallEndIcon from "@material-ui/icons/CallEnd";
import ringtone from "../utils/ringtones/Sony_Ericsson-Music_Box.mp3";
import useStyles from "./useStyles";
import { api } from "../utils/userApi";
const ResponseCall = () => {
  const { answerCall, rejectCall, callAccepted, call } =
    useContext(CallContext);
  const navigate = useNavigate();
  const handleAnswerCall = (e) => {
    e.preventDefault();
    answerCall();
    call.isVideoCall ? navigate("/videoCall") : navigate("/voiceCall");
  };
  const classes = useStyles();
  return call?.isRecievedCall && !callAccepted ? (
    <div className={classes.responseCallDiv}>
      <audio src={ringtone} autoPlay loop />
      <Container className={classes.responseCallContainer}>
        <Avatar
          src={`${api}/${call.image}`}
          alt={call?.name}
          className={classes.largeAvatar}
          style={{ margin: "1rem auto" }}
        />
        <Typography
          variant="h5"
          color="textPrimary"
          style={{ textAlign: "center" }}
        >
          {call.name} is calling you .....
        </Typography>{" "}
        <Grid
          container
          spacing={1}
          justifyContent="center"
          style={{ paddingTop: "1rem" }}
        >
          <Grid item>
            <IconButton
              variant="contained"
              className={classes.answerCallIcon}
              onClick={(e) => handleAnswerCall(e)}
            >
              {call.isVideoCall ? <VideoCallIcon /> : <CallIcon />}
            </IconButton>{" "}
          </Grid>
          <Grid item>
            <IconButton
              variant="contained"
              className={classes.rejectCallIcon}
              onClick={() => {
                rejectCall();
              }}
            >
              <CallEndIcon />
            </IconButton>{" "}
          </Grid>
        </Grid>
      </Container>
    </div>
  ) : null;
};

export default ResponseCall;
