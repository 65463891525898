import {
  Avatar,
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  List,
  ListItem,
  Typography,
} from "@material-ui/core";
import ReactLoading from "react-loading";

import CloseIcon from "@material-ui/icons/Close";
import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { api, getProfileByName } from "../utils/userApi";
import useStyles from "./useStyles";
import SearchIcon from "@material-ui/icons/Search";

const FriendList = () => {
  const user = useSelector((state) => state.profile);
  const { friendList } = user;
  const friends = friendList.filter((friend) => friend.status === "friend");
  const classes = useStyles();
  const navigate = useNavigate();
  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchResult, setSearchResult] = useState([]);
  const [isSearchResultOpen, setIsSearchResultOpen] = useState(false);
  const queryRef = useRef();
  const handleSearch = async (e) => {
    e.preventDefault();
    setSearchLoading(true);
    queryRef.current.value.trim() !== ""
      ? await getProfileByName(queryRef.current.value)
          .then((res) => setSearchResult(res.result))
          .then(() => setIsSearchResultOpen(true))
          .catch((err) => {
            console.log(err);
            setSearchResult([]);
          })
      : setSearchResult([]);
    setSearchLoading(false);
  };

  const searchSection = (
    <div className={classes.searchNewSection}>
      <div style={{ textAlign: "right" }}>
        <IconButton
          className={classes.closeSearch}
          onClick={(e) => setIsSearchOpen(false)}
        >
          <CloseIcon />
        </IconButton>
      </div>
      <form onSubmit={(e) => handleSearch(e)}>
        <Grid container style={{ flexWrap: "nowrap" }}>
          <Grid item>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Find new friends…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputRef={queryRef}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </Grid>
          <Grid item>
            {" "}
            <Button variant="contained" color="secondary" type="submit">
              Search
            </Button>
          </Grid>
        </Grid>
      </form>

      {searchLoading ? (
        <div
          style={{
            display: "inline-block",
            position: "fixed",
            top: "45%",
            left: "45%",
          }}
        >
          <ReactLoading type="spin" color="#f9b130" height={50} width={50} />
        </div>
      ) : (
        isSearchResultOpen &&
        (searchResult.length > 0 ? (
          <List style={{ maxHeight: "50vh", overflow: "auto" }}>
            {searchResult.map((friend) => (
              <ListItem
                key={friend._id}
                style={{
                  borderBottom: "2px solid #1e1e1e",
                  padding: "1rem 0",
                }}
              >
                <Grid
                  container
                  justifyContent="flex-start"
                  wrap="nowrap"
                  spacing={1}
                  direction="row"
                  alignItems="center"
                  onClick={() => navigate(`/profile/${friend._id}`)}
                  style={{
                    color: "#f9b130",
                    width: "fit-content",
                    cursor: "pointer",
                  }}
                >
                  <Grid item>
                    <Avatar
                      style={
                        friend?.isOnline
                          ? { border: "3px solid #adff2f" }
                          : { border: "3px solid #808080" }
                      }
                      alt={friend.firstName}
                      src={`${api}/${friend.image}`}
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="body1">{`${friend.name}`}</Typography>
                  </Grid>
                </Grid>
              </ListItem>
            ))}{" "}
          </List>
        ) : (
          <div className={classes.noResult}>
            <Typography varient="body2">
              There is no result contains " {queryRef?.current?.value} "
            </Typography>
          </div>
        ))
      )}
    </div>
  );

  return (
    <Container maxWidth="md" className={classes.chatPageContainer}>
      <Grid
        container
        justifyContent="space-between"
        className={classes.friendListHeader}
      >
        <Grid item>
          <Typography variant="h5">My Friends</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => setIsSearchOpen(true)}
          >
            Add Friend
          </Button>
        </Grid>
      </Grid>
      {friends.length > 0 ? (
        <List>
          {friends.map((friend) => (
            <ListItem
              key={friend._id}
              style={{
                borderBottom: "2px solid #1e1e1e",
                padding: "1rem 0",
              }}
            >
              <Grid
                container
                justifyContent="flex-start"
                wrap="nowrap"
                spacing={1}
                direction="row"
                alignItems="center"
                onClick={() => navigate(`/profile/${friend._id}`)}
              >
                <Grid item>
                  <Avatar
                    style={
                      friend?.isOnline
                        ? { border: "3px solid #adff2f" }
                        : { border: "3px solid #808080" }
                    }
                    alt={friend.firstName}
                    src={`${api}/${friend.image}`}
                  />
                </Grid>
                <Grid item>
                  <Typography variant="body1">
                    {`${friend.firstName} ${friend.lastName}`}
                  </Typography>
                </Grid>
              </Grid>
            </ListItem>
          ))}
        </List>
      ) : (
        <div className={classes.noFriends}>
          <Typography variant="subtitle2" style={{ paddingBottom: "1rem" }}>
            There is no friends in your friend list.Add new friends to be able
            to chat with them.
          </Typography>
          <Button
            variant="outlined"
            color="primary"
            onClick={(e) => setIsSearchOpen(true)}
          >
            Add Friend
          </Button>
        </div>
      )}
      {isSearchOpen && searchSection}
    </Container>
  );
};

export default FriendList;
