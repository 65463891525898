import {
  ADD_NOTIFICATION,
  GET_NOTIFICATIONS,
  RESET_NEW_NOTIFICATION,
  SET_READ_NOTIFICATION,
} from "../actions/notificationAction";
import { CLEAR_STORE } from "../actions/userActions";

export default function notifications(state = [], action) {
  switch (action.type) {
    case GET_NOTIFICATIONS:
      return action.notifications;

    case ADD_NOTIFICATION:
      return state.concat(action.notification);

    case RESET_NEW_NOTIFICATION:
      return state.map((notification) => {
        if (notification.new === true) {
          return {
            ...notification,
            new: false,
          };
        } else {
          return notification;
        }
      });
    case SET_READ_NOTIFICATION:

      return state.map((notification) => {
        if (notification.date === action.date) {
          return { ...notification, isRead: true };
        } else {
          return notification;
        }
      });
    case CLEAR_STORE:
      return [];

    default:
      return state;
  }
}
