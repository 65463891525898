import React, { createContext, useEffect, useRef, useState } from "react";
import Peer from "simple-peer";
const CallContext = createContext();

const ContextProvider = ({ socket, myId, myName, myImage, children }) => {
  const [stream, setStream] = useState(null);
  const [call, setCall] = useState(null);
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [callStatus, setCallStatus] = useState(null);
  const myMedia = useRef();
  const userMedia = useRef();
  const connectionRef = useRef();
  useEffect(() => {
    const destroyCall = () => {
      setCallEnded(true);
      connectionRef.current.destroy();
      setTimeout(() => window.location.reload(), 1500);
    };
    socket?.on(
      "callUser",
      ({
        from,
        userOnCallId,
        name: callerName,
        image: callerImage,
        signal,
        isVideoCall,
      }) => {
        if (call) {
          socket.emit("userBusy", { caller: from, busyUser: socket.id });
        } else {
          setCall({
            isRecievedCall: true,
            userOnCallSocketId: from,
            userOnCallId,
            name: callerName,
            image: callerImage,
            signal,
            isVideoCall,
          });
        }
      }
    );
    socket?.on("userBusy", ({ busyUser }) => {
      setCallStatus("busy");
      setTimeout(() => destroyCall(), 3000);
    });
    socket?.on("callCanceled", ({ from }) => {
      return call?.userOnCallSocketId === from
        ? callAccepted
          ? destroyCall()
          : setCall(null)
        : null;
    });

    socket?.on("callRejected", () => {
      setCallStatus("rejected");
      setTimeout(() => destroyCall(), 2000);
    });
    socket?.on("callEnded", () => {
      setCallStatus("callEnded");
      setTimeout(() => destroyCall(), 2000);
    });
    return () => {
      socket?.off("callUser");
      socket?.off("userBusy");
      socket?.off("callCanceled");
      socket?.off("callRejected");
    };
  }, [socket, call, callAccepted]);

  useEffect(() => {
    const clearNoResponseCall = setTimeout(() => {
      if (call && !call.isRecievedCall && !callAccepted) {
        socket.emit("callCanceled", {
          id: call?.userOnCallId,
          from: socket.id,
        });
        setCallStatus("noResponse");
        setCallEnded(true);
        connectionRef.current.destroy();
        setTimeout(() => window.location.reload(), 1500);
      }
    }, 25000);
    return () => {
      clearTimeout(clearNoResponseCall);
    };
  }, [call, callAccepted, socket]);

  const makeCall = async (id, friendName, friendImage, isVideoCall) => {
    navigator.mediaDevices
      .getUserMedia({ video: isVideoCall, audio: true })
      .then((myStream) => {
        setCall({
          isRecievedCall: false,
          userOnCallId: id,
          name: friendName,
          isVideoCall,
          image: friendImage,
        });
        setStream(myStream);
        setCallStatus("connecting");
        const peer = new Peer({
          initiator: true,
          trickle: false,
          stream: myStream,
          isVideoCall,
        });
        peer.on("signal", (data) => {
          socket.emit(
            "callUser",
            {
              friendId: id,
              signalData: data,
              from: socket.id,
              name: myName,
              image: myImage,
              isVideoCall,
              myId,
            },
            (res) => {
              res.message === "ok"
                ? setCallStatus("ringing")
                : setCallStatus("error");
            }
          );
        });
        peer.on("stream", (userStream) => {
          userMedia.current.srcObject = userStream;
        });
        socket.on("callAccepted", (signal) => {
          setCallAccepted(true);
          peer.signal(signal);
        });
        connectionRef.current = peer;
        peer.on("close", () => {
          setCallStatus("callEnded");
          setCallEnded(true);
          connectionRef.current.destroy();
          setTimeout(() => window.location.reload(), 1000);
        });
      });
  };

  const answerCall = () => {
    navigator.mediaDevices
      .getUserMedia({ video: call.isVideoCall, audio: true })
      .then((myStream) => {
        setStream(myStream);
        setCallAccepted(true);
        const peer = new Peer({
          initiator: false,
          trickle: false,
          stream: myStream,
        });
        peer.on("signal", (data) => {
          socket.emit("answerCall", {
            signal: data,
            to: call.userOnCallSocketId,
          });
        });
        peer.on("stream", (userStream) => {
          userMedia.current.srcObject = userStream;
        });
        peer.signal(call.signal);
        connectionRef.current = peer;
        peer.on("close", () => {
          setCallStatus("callEnded");
          setCallEnded(true);
          connectionRef.current.destroy();
          setTimeout(() => window.location.reload(), 1000);
        });
      })
      .catch((err) => console.log(err.message));
  };

  const endCall = () => {
    callAccepted
      ? socket.emit("callEnded", call?.userOnCallId)
      : socket.emit("callCanceled", {
          id: call?.userOnCallId,
          from: socket.id,
        });
    setCallEnded(true);
    connectionRef.current.destroy();
    setTimeout(() => window.location.reload(), 500);
  };
  const rejectCall = () => {
    socket.emit("callRejected", call?.userOnCallSocketId);
    setCall(null);
  };

  const handleCallStatus = () => {
    switch (callStatus) {
      case "connecting":
        return "connecting ...";
      case "ringing":
        return "ringing ...";
      case "busy":
        return `${call?.name} is busy`;
      case "rejected":
        return "call rejected";
      case "noResponse":
        return "call ended";
      case "callEnded":
        return "call ended";
      default:
        return "";
    }
  };
  return (
    <CallContext.Provider
      value={{
        stream,
        call,
        callAccepted,
        callEnded,
        name: myName,
        myMedia,
        userMedia,
        answerCall,
        makeCall,
        endCall,
        rejectCall,
        setStream,
        callStatus,
        handleCallStatus,
      }}
    >
      {children}
    </CallContext.Provider>
  );
};
export { CallContext, ContextProvider };
