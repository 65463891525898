import { combineReducers } from "redux";
import profile from "./profileReducer";
import notifications from "./notificationReducer";
import conversations from "./conversationReducer"

export default combineReducers({
  profile,
  notifications,
  conversations
});
