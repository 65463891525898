import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  TextareaAutosize,
  Typography,
} from "@material-ui/core";
import { LockOutlined } from "@material-ui/icons";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeImage } from "../store/actions/userActions";
import { api } from "../utils/userApi";
import useStyles from "./useStyles";

const CompleteInfo = () => {
  const user = useSelector((state) => state.profile);
  const dispatch = useDispatch();
  const { firstName, lastName, image } = user;
  const [newImage, setNewImage] = useState();

  const handleCompleteSignup = (e) => {};
  const handleImageInput = (e) => {
    e.preventDefault();
    // function for Image store & preview
    // setImage(e.target.files[0]);
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const img = document.createElement("img");
      img.onload = () => {
        const maxHeight = 300;
        const maxWidth = 200;
        let height = img.height;
        let width = img.width;
        if (width > maxWidth) {
          height = height * (maxWidth / width);
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = width * (maxHeight / height);
          height = maxHeight;
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.imageSmoothingEnabled = true;
        const dataURL = canvas.toDataURL(imageFile.type);
        canvas.toBlob((blob) => {
          const newImage = new File([blob], imageFile.name, {
            type: imageFile.type,
          });
          setNewImage(newImage);
        });
        dispatch(changeImage(dataURL));
      };
      img.src = e.target.result;
      //dispatch(addInfo("cvImage", reader.result));
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="sm">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Complete your profile
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <label htmlFor="img-input" style={{ margin: "auto" }}>
                <Avatar
                  className={classes.largeAvatar}
                  style={{ margin: "auto" }}
                  alt={firstName}
                  src={newImage ? image : image && `${api}/${image}`}
                />{" "}
              </label>
              <Input
                type="file"
                id="img-input"
                accept="image/*"
                style={{ visibility: "hidden" }}
                onChange={(e) => handleImageInput(e)}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" style={{ width: "100%" }}>
                <FormLabel component="legend"> Date of birth:</FormLabel>

                <Input type="date" id="dob" />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <FormControl component="fieldset">
                <FormLabel component="legend">Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  defaultValue="Male"
                  name="gender"
                  required
                >
                  <FormControlLabel
                    value="Female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="Male"
                    control={<Radio />}
                    label="Male"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" style={{ width: "100%" }}>
                <FormLabel component="legend">About:</FormLabel>
                <TextareaAutosize
                  aria-label="minimum height"
                  variant="outlined"
                  required
                  minRows={5}
                  placeholder="Write a brief about yourself"
                />
              </FormControl>
            </Grid>
          </Grid>
          {/* {alertMessage && <div className="">{alertMessage}</div>} */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleCompleteSignup(e)}
          >
            Submit
          </Button>
        </form>
      </div>
    </Container>
  );
};

export default CompleteInfo;
