import {
  GET_CONVERSATIONS,
  NEW_MESSAGE,
  SEEN_MESSAGES,
} from "../actions/conversationAction";

export default function conversations(state = [], action) {
  switch (action.type) {
    case GET_CONVERSATIONS:
      return action.conversations;

    case NEW_MESSAGE:
      return state.map((conversation) => {
        if (conversation._id === action.conversationId) {
          return {
            ...conversation,
            messages: [...conversation.messages, action.message],
          };
        } else {
          return conversation;
        }
      });
    case SEEN_MESSAGES:
      return state.map((conversation) =>
        conversation._id === action.conversationId
          ? {
              ...conversation,
              messages: conversation.messages.map((message) =>
                action.messages.includes(message)
                  ? { ...message, seen: true }
                  : message
              ),
            }
          : conversation
      );
    default:
      return state;
  }
}
