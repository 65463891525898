import { useDispatch } from "react-redux";
import {
  acceptFriendRequest,
  addFriendRequest,
  removeFriendRequest,
} from "../store/actions/userActions";
import {
  fetchAcceptFriend,
  fetchFriendRequest,
  fetchRemoveFriend,
  saveNewImage,
} from "../utils/userApi";

export const useFriendRequest = (user, friend, socket) => {
  const dispatch = useDispatch();

  const handleAddRequest = async (e) => {
    e.preventDefault();
    dispatch(
      addFriendRequest({
        firstName: friend.firstName,
        lastName: friend.lastName,
        image: friend.image,
        _id: friend._id,
      })
    );
    fetchFriendRequest(
      {
        firstName: user.firstName,
        lastName: user.lastName,
        image: user.image,
        _id: user._id,
      },
      {
        firstName: friend.firstName,
        lastName: friend.lastName,
        image: friend.image,
        _id: friend._id,
      }
    ).then((res) => {
      if (res.message === "ok") {
        socket?.emit("addFriend", {
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            image: user.image,
            _id: user._id,
          },
          friend: {
            _id: friend._id,
          },
        });
      }
    });
  };
  const handleDeleteFriend = async (e, type) => {
    e.preventDefault();
    dispatch(removeFriendRequest(friend._id));
    fetchRemoveFriend(
      {
        firstName: user.firstName,
        lastName: user.lastName,
        image: user.image,
        _id: user._id,
        type,
      },
      friend._id
    ).then((res) => {
      if (res.message === "ok") {
        socket?.emit("deleteFriend", {
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            image: user.image,
            _id: user._id,
            type,
          },
          friendId: friend._id,
        });
      }
    });
  };
  const handleAcceptRequest = (e) => {
    e.preventDefault();
    fetchAcceptFriend(
      {
        firstName: user.firstName,
        lastName: user.lastName,
        image: user.image,
        _id: user._id,
      },
      friend._id
    ).then((res) => {
      if (res.message === "ok") {
        dispatch(acceptFriendRequest(friend._id, res.conversationId));

        socket?.emit("acceptRequest", {
          user: {
            firstName: user.firstName,
            lastName: user.lastName,
            image: user.image,
            _id: user._id,
            conversationId: res.conversationId,
          },
          friendId: friend._id,
        });
      }
    });
  };
  const handleImageInput = (e, setNewImage) => {
    e.preventDefault();
    // function for Image store & preview
    // setImage(e.target.files[0]);
    const imageFile = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = (e) => {
      const img = document.createElement("img");
      img.onload = () => {
        const maxHeight = 300;
        const maxWidth = 200;
        let height = img.height;
        let width = img.width;
        if (width > maxWidth) {
          height = height * (maxWidth / width);
          width = maxWidth;
        }
        if (height > maxHeight) {
          width = width * (maxHeight / height);
          height = maxHeight;
        }

        const canvas = document.createElement("canvas");
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0, width, height);
        ctx.imageSmoothingEnabled = true;
        const dataURL = canvas.toDataURL(imageFile.type);
        canvas.toBlob((blob) => {
          const newImage = new File([blob], imageFile.name, {
            type: imageFile.type,
          });
          saveNewImage(newImage).then((res) => {
            if (res.message === "ok") {
            } else {
              setNewImage(null);
              return res.message;
            }
          });
        });
        setNewImage(dataURL);
      };
      img.src = e.target.result;
    };
    reader.readAsDataURL(e.target.files[0]);
  };
  return {
    handleAddRequest,
    handleDeleteFriend,
    handleAcceptRequest,
    handleImageInput,
  };
};
