import React from "react";
import { useState } from "react";
import { useParams } from "react-router";
import { resetPassword } from "../utils/userApi";
import { Link } from "react-router-dom";
import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import useStyles from "./useStyles";

const ResetPassword = () => {
  const params = useParams();
  const resetToken = params.resetToken;
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const handleSubmit = (e) => {
    setDisableSubmit(true);
    e.preventDefault();
    password
      ? password === confirmPass
        ? resetPassword(password, resetToken).then((res) => {
            setAlertMessage(res.message);
          })
        : setAlertMessage("New password and confirm password aren't matching")
      : setAlertMessage("please enter your new password");
    setDisableSubmit(false);
  };
  const classes = useStyles();
  return (
    <Container component="main" maxWidth="sm">
      <div className={classes.paper}>
        <Typography component="h1" variant="h5" paragraph>
          Reset Password
        </Typography>
        {alertMessage === "ok" ? (
          <div className="alert largeAlert alert-dark">
            password has been changed successfully <br />
            <Link to="/Login">Click here to login </Link>
          </div>
        ) : (
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              {/* password input */}
              <Grid item xs={12}>
                <TextField
                  autoComplete="password"
                  variant="outlined"
                  type="password"
                  required
                  fullWidth
                  id="password"
                  label="Enter New Password"
                  autoFocus
                  name="password"
                  placeholder="New Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>

              {/* confirm password input */}
              <Grid item xs={12}>
                <TextField
                  autoComplete="password"
                  variant="outlined"
                  type="password"
                  required
                  fullWidth
                  id="confirm_password"
                  label="Confirm Password"
                  autoFocus
                  name="confirm_password"
                  placeholder="Confirm Password"
                  value={confirmPass}
                  onChange={(e) => setConfirmPass(e.target.value)}
                />
              </Grid>

              {/* alert messages div */}
              {alertMessage && (
                <div
                  className="alert largeAlert alert-warning"
                  role="alertdialog"
                >
                  {alertMessage}
                </div>
              )}
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                disabled={disableSubmit}
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </Button>
            </Grid>
          </form>
        )}
      </div>
    </Container>
  );
};

export default ResetPassword;
