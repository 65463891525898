export const GET_CONVERSATIONS = "GET_CONVERSATIONS";
export const NEW_MESSAGE = "NEW_MESSAGE";
export const SEEN_MESSAGES = "SEEN_MESSAGES";

export const getConversations = (conversations) => {
  return {
    type: GET_CONVERSATIONS,
    conversations,
  };
};
export const addNewMessage = (conversationId, message) => {
  return {
    type: NEW_MESSAGE,
    conversationId,
    message,
  };
};
export const setSeenMessages = (conversationId, messages) => {
  return {
    type: SEEN_MESSAGES,
    conversationId,
    messages,
  };
};
