import {
  Avatar,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { getProfile, login } from "../utils/userApi";
import useStyles from "./useStyles";
import { LockOutlined } from "@material-ui/icons";
import { useDispatch } from "react-redux";
import { addProfileData } from "../store/actions/userActions";
import { getNotifications } from "../store/actions/notificationAction";
import { getConversations } from "../store/actions/conversationAction";

const Login = ({ isUser, setIsUser }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const dispatch = useDispatch();

  const handleLogin = (e) => {
    e.preventDefault();
    if (email && password) {
      login({
        email,
        password,
      }).then(async (result) => {
        if (result.message === "ok") {
          const { notifications, ...user } = result.user;
          user?.friendList?.length > 0
            ? await Promise.all(
                user.friendList.map(async (friend) => {
                  const res = await getProfile(friend._id)
                    .then((res) => {
                      return {
                        ...res.user,
                        conversationId: friend.conversationId,
                        status: friend.status,
                      };
                    })
                    .catch((err) => console.log(err.message));
                  return res;
                })
              ).then((res) =>
                dispatch(addProfileData({ ...user, friendList: res }))
              )
            : dispatch(addProfileData(user));
          dispatch(getNotifications(notifications));
          dispatch(getConversations(result.conversations));
          setIsUser(true);
        } else {
          result?.message && setAlertMessage(result.message);
        }
      });
    } else {
      setAlertMessage("All fields are required");
    }
  };

  useEffect(() => {}, [isUser]);
  const classes = useStyles();

  return isUser ? (
    <Navigate to="/" />
  ) : (
    <Container component="main" maxWidth="xs">
      {/* <CssBaseline /> */}
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        <form className={classes.form} noValidate>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          {/* alert messages div */}
          {alertMessage && (
            <div className="alert largeAlert alert-warning " role="alertdialog">
              {alertMessage}
            </div>
          )}
          {/* ========================================================== */}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleLogin(e)}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link to="/forgotPassword" variant="body2">
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link to="/signup" variant="body2">
                {"Don't have an account? Sign Up"}
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default Login;
