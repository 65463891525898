import {
  Avatar,
  Button,
  Container,
  CssBaseline,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Input,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { LockOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, Navigate } from "react-router-dom";
import { addProfileData } from "../store/actions/userActions";
import { getNotifications } from "../store/actions/notificationAction";
import { createUser } from "../utils/userApi";
import useStyles from "./useStyles";

const Signup = ({ isUser, setIsUser }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [gender, setGender] = useState("");
  const [dob, setDob] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPass, setConfirmPass] = useState("");
  const [alertMessage, setAlertMessage] = useState(null);
  const dispatch = useDispatch();
  const classes = useStyles();

  const handleSignup = (e) => {
    e.preventDefault();
    e.target.disabled = true;
    const strongRegex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})"
      // "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );

    if (firstName && lastName && email && password && gender && dob) {
      if (strongRegex.test(password)) {
        password === confirmPass
          ? createUser({
              firstName,
              lastName,
              gender,
              dob,
              email,
              password,
            }).then((result) => {
              if (result.message === "ok") {
                const { notifications, ...user } = result.user;
                dispatch(addProfileData(user));
                dispatch(getNotifications(notifications));
                setIsUser(true);
              } else {
                result?.message && setAlertMessage(result.message);
              }
            })
          : setAlertMessage("confirm password doesn't match");
      } else {
        password.length < 8
          ? setAlertMessage("password must have at least 8 characters")
          : setAlertMessage(
              "password Must contain at least one number, uppercase and lowercase letter"
            );
      }
    } else {
      setAlertMessage("All fields required");
    }
    e.target.disabled = false;
  };
  useEffect(() => {}, [isUser]);

  return isUser ? (
    <Navigate to="/" />
  ) : (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlined />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign up
        </Typography>
        <form className={classes.form} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoComplete="fname"
                name="firstName"
                variant="outlined"
                required
                fullWidth
                id="firstName"
                label="First Name"
                autoFocus
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                color="secondary"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="lastName"
                label="Last Name"
                name="lastName"
                autoComplete="lname"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>{" "}
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                required
                className={classes.formControlInput}
              >
                <FormLabel> Date of birth:</FormLabel>
                <Input
                  type="date"
                  id="dob"
                  name="dob"
                  onChange={(e) => setDob(e.target.value)}
                  style={{ margin: "0" }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl
                component="fieldset"
                required
                className={classes.formControlInput}
              >
                <FormLabel>Gender</FormLabel>
                <RadioGroup
                  aria-label="gender"
                  defaultValue="Male"
                  name="gender"
                  required
                  style={{
                    flexDirection: "row",
                  }}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <FormControlLabel
                    value="female"
                    control={<Radio />}
                    label="Female"
                  />
                  <FormControlLabel
                    value="male"
                    control={<Radio />}
                    label="Male"
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />{" "}
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                name="confirmPassword"
                label="confirmPassword"
                type="password"
                id="confirmPassword"
                value={confirmPass}
                autoComplete="current-password"
                onChange={(e) => setConfirmPass(e.target.value)}
              />
            </Grid>
          </Grid>
          {alertMessage && (
            <Alert variant="filled" severity="error">
              {alertMessage}
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={(e) => handleSignup(e)}
          >
            Sign Up
          </Button>
          <Grid container justifyContent="flex-end">
            <Grid item>
              <Link to="/Login" variant="body2">
                Already have an account? Sign in
              </Link>
            </Grid>
          </Grid>
        </form>
      </div>
    </Container>
  );
};

export default Signup;
