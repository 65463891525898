import { Container, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import useStyles from "./useStyles";

const NotFound = () => {
  const classes = useStyles();
  return (
    <Container className={classes.notFound} maxWidth="sm" align="center">
      <Container
        className={classes.chatPageContainer}
        align="center"
        style={{ padding: "3rem" }}
      >
        <Typography variant="h1" className="notURL">
          404
        </Typography>
        <Typography variant="subtitle1" className="notExist">
          Sorry the requested page does not exist.
        </Typography>

        <Link to="/" className={classes.linkToHome}>
          Home
        </Link>
      </Container>
    </Container>
  );
};

export default NotFound;
