import React, { useEffect, useRef } from "react";
import ReactLoading from "react-loading";
import {
  Avatar,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import CloseIcon from "@material-ui/icons/Close";
import { useDispatch, useSelector } from "react-redux";
import {
  addNewMessage,
  setSeenMessages,
} from "../store/actions/conversationAction";
import useStyles from "./useStyles";
import { postNewMessage, fetchSeenMessages, api } from "../utils/userApi";
import { displayDate } from "../utils/displayDate";

const Conversation = ({ user, friendToChat, setFriendToChat, socket }) => {
  const dispatch = useDispatch();
  const newMessage = useRef();
  const messageBox = useRef();
  const classes = useStyles();
  const conversations = useSelector((state) => state.conversations);
  const { conversationId, ...friend } = friendToChat;
  const targetConversation = conversations.find(
    (conversation) => conversation._id === conversationId
  );
  const unseenMessages = targetConversation.messages.filter(
    (message) => message.seen === false && message.senderId !== user._id
  );

  const messages = targetConversation?.messages;

  useEffect(() => {
    if (unseenMessages.length > 0) {
      fetchSeenMessages(conversationId, friend._id);
      dispatch(setSeenMessages(conversationId, unseenMessages));
    }
  }, [dispatch, conversationId, friend._id, unseenMessages]);
  const handleSendMessage = (e) => {
    e.preventDefault();
    const message = {
      senderId: user._id,
      message: newMessage.current.value,
      date: Date.now(),
    };
    dispatch(addNewMessage(conversationId, message));
    postNewMessage(conversationId, message).then((res) => {
      res.message === "ok"
        ? socket?.emit("sendMessage", {
            friendId: friend._id,
            conversationId,
            message: res.newMessage,
          })
        : console.log(res.message);
    });
    newMessage.current.value = null;
    newMessage.current.focus();
  };

  useEffect(() => {
    messageBox?.current?.scrollIntoView();
  }, [messages, friend, conversations]);
  return friend ? (
    <Grid
      className={classes.chatBox}
      container
      direction="column"
      spacing={1}
      wrap="nowrap"
      justifyContent="space-between"
    >
      <Grid
        container
        direction="row"
        wrap="nowrap"
        spacing={1}
        alignItems="center"
        className={classes.friendInfoBox}
        style={{
          filter: friend?.status !== "friend" ? "brightness(0.5)" : "none",
        }}
      >
        <Grid item>
          <Avatar alt={friend.firstName} src={`${api}/${friend.image}`} />
          <Typography
            variant="subtitle1"
            style={
              friend.isOnline ? { color: "#adff2f" } : { color: "#808080" }
            }
          >
            {friend.isOnline ? "online" : "offline"}{" "}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {friend.firstName} {friend.lastName}
          </Typography>
        </Grid>
        <IconButton
          className={classes.CloseChatIcon}
          onClick={() => setFriendToChat(null)}
        >
          <CloseIcon />
        </IconButton>
      </Grid>
      <Grid item className={classes.messagesBox}>
        {messages?.length > 0 ? (
          messages.map((message) => (
            <Grid
              container
              direction={message.senderId === user._id ? "row-reverse" : "row"}
              alignItems="flex-end"
              key={message.date}
            >
              <Grid item>
                <Avatar
                  alt={friend.firstName}
                  src={`${api}/${
                    message.senderId === user._id ? user.image : friend.image
                  }`}
                />
              </Grid>
              <Grid
                item
                className={
                  message.senderId === user._id
                    ? classes.userMessage
                    : classes.friendMessage
                }
                ref={messageBox}
              >
                {" "}
                <Typography variant="subtitle2">{message.message}</Typography>
              </Grid>
              <Grid item className={classes.dateDisplay}>
                {displayDate(message.date)}
              </Grid>
            </Grid>
          ))
        ) : (
          <Typography variant="subtitle2">
            There is no previous messages to show
          </Typography>
        )}
      </Grid>
      <Grid item>
        {friend.status !== "friend" && (
          <Typography variant="caption" style={{ color: "#9f6f1b" }}>
            Sorry, You can't text {friend.firstName} because you are not in his
            friendlist.
          </Typography>
        )}
        <form onSubmit={(e) => handleSendMessage(e)}>
          <TextField
            label="your message ....."
            autoFocus
            // onChange={(e) => setNewMessage(e.target.value)}
            inputRef={newMessage}
            className={classes.typeMessage}
            disabled={friend.status !== "friend"}
          />
          <IconButton
            aria-label="Send"
            color="secondary"
            type="submit"
            disabled={friend.status !== "friend"}
          >
            <SendIcon />
          </IconButton>
        </form>
      </Grid>
    </Grid>
  ) : (
    <div className={classes.loadingChatContainer}>
      <div>
        <ReactLoading type="spokes" color="#191919" height={50} width={50} />
      </div>
    </div>
  );
};

export default Conversation;
