export const GET_NOTIFICATIONS = "GET_NOTIFICATIONS";
export const ADD_NOTIFICATION = "ADD_NOTIFICATION";
export const RESET_NEW_NOTIFICATION = "RESET_NEW_NOTIFICATION";
export const SET_READ_NOTIFICATION = "SET_READ_NOTIFICATION";

export const getNotifications = (notifications) => {
  return { type: GET_NOTIFICATIONS, notifications };
};

export const addNotification = (notification) => {
  return {
    type: ADD_NOTIFICATION,
    notification,
  };
};
export const resetNewNotification = () => {
  return {
    type: RESET_NEW_NOTIFICATION,
  };
};
export const setIsReadNotification = (date) => {
  return {
    type: SET_READ_NOTIFICATION,
    date,
  };
};
